import { createCtx } from '@okam/core-lib'
import { useMemo } from 'react'
import { css, theme } from 'twin.macro'
import type { TThemeProviderProps, TThemePanelContext, TDefaultTheme, TTheme } from './interface'

const defaultTheme: TDefaultTheme = {
  typography: () => [
    css({
      '--okam-font-size-h1': theme`fontSize.4xl`,
    }),
  ],
}

export const [useTheme, ThemeProvider] = createCtx<TThemePanelContext<TTheme>>()

export function ThemeContextProvider({ children, brandTheme = defaultTheme }: TThemeProviderProps) {
  const value = useMemo<TThemePanelContext>(() => ({ brandTheme }), [brandTheme])
  return <ThemeProvider value={value}>{children}</ThemeProvider>
}
