import type { TProcessingInstruction } from '@okam/html2react'
import { parseHtmlStyle } from '@okam/html2react'
import { nanoid } from 'nanoid'
import Link from 'next/link'
import React from 'react'

const replaceHrefLink: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return node.type != null && node.type === 'tag' && node.name === 'a'
  },

  processNode(node, children) {
    const { attribs } = node
    const id = nanoid()
    return (
      <Link
        key={id}
        className={attribs.class}
        style={parseHtmlStyle(attribs.style)}
        href={attribs.href}
        {...(attribs.target && {
          target: attribs.target,
          rel: 'noopener noreferrer',
        })}
      >
        {children}
      </Link>
    )
  },
}

export default replaceHrefLink
