/* eslint-disable @typescript-eslint/naming-convention */
import type { CSSObject } from '@emotion/react'
import { css, theme } from 'twin.macro'
import type { TTypographyToken } from './interface'

export const baseToken: TTypographyToken = {
  '--okam-font-family-display': theme`fontFamily.sans`,
  '--okam-font-size-span': theme`fontSize.base`,
  '--okam-font-size-h1': theme`fontSize.7xl`,
  '--okam-font-size-h2': theme`fontSize.6xl`,
  '--okam-font-size-h3': theme`fontSize.5xl`,
  '--okam-font-size-h4': theme`fontSize.4xl`,
  '--okam-font-size-h5': theme`fontSize.3xl`,
  '--okam-font-size-h6': theme`fontSize.2xl`,
  '--okam-font-size-p': theme`fontSize.base`,
}

const tokens = css(baseToken as CSSObject)

export default tokens
