import React from 'react'
import { useListBox, useListBoxSection, useOption, useSeparator } from 'react-aria'
import tw from 'twin.macro'
import type { TLBSectionProps, TListBoxProps, TOptionProps } from './Listbox.interface'

const Option = ({ item, state }: TOptionProps) => {
  const ref = React.useRef(null)
  const { optionProps, isSelected } = useOption({ key: item.key }, state, ref)

  return (
    <li {...optionProps} ref={ref} css={[tw`transition hover:bg-gray-2 w-full px-4`, isSelected && tw`font-bold`]}>
      {item.rendered}
    </li>
  )
}

export const ListBoxSection = (props: TLBSectionProps) => {
  const { section, state } = props
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section?.rendered,
  })
  const separatorProps = useSeparator({
    elementType: 'li',
  })

  return (
    <>
      {section.key !== state.collection.getFirstKey() && <li tw="pt-6" {...separatorProps} />}
      <li {...itemProps}>
        {section.rendered && <span {...headingProps}>{section.rendered}</span>}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  )
}

const ListBox = (props: TListBoxProps) => {
  const ref = React.useRef(null)
  const { listBoxRef = ref, state } = props
  const { listBoxProps } = useListBox(props, state, listBoxRef)

  return (
    <ul {...listBoxProps} ref={listBoxRef} tw="p-2 my-4 rounded-md border">
      {[...state.collection].map((item) => {
        return item.type === 'section' ? (
          <ListBoxSection key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        )
      })}
    </ul>
  )
}

export { ListBox, Option }
