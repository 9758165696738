import tw, { css } from 'twin.macro'
import type { ButtonVariant, ButtonStyle } from './interface'

const variants = {
  primary: tw`bg-brand-color-1-1 hover:bg-brand-color-1-1 active:bg-brand-color-1-1 disabled:bg-brand-color-1-disabled`,
  gray: tw`bg-brand-color-3-1 text-black hover:bg-brand-color-3-1 active:bg-brand-color-3-1 disabled:bg-brand-color-3-disabled`,
  black: tw`bg-black hover:bg-gray-5 active:bg-gray-5 disabled:bg-gray-2`,
  white: tw`bg-white text-black hover:bg-gray-3 active:bg-gray-3 disabled:bg-gray-2`,
  default: tw`bg-brand-color-1-1 hover:bg-brand-color-1-1 active:bg-brand-color-1-1 disabled:bg-brand-color-1-disabled`,
}

const isHollowStyle = 'bg-transparent disabled:bg-transparent'

const hollowVariants = {
  primary: tw`${isHollowStyle} text-brand-color-1-1 disabled:text-brand-color-1-disabled`,
  gray: tw`${isHollowStyle} text-brand-color-3-1 disabled:text-brand-color-3-disabled`,
  black: tw`${isHollowStyle} text-black disabled:text-gray-2`,
  white: tw`bg-transparent text-white disabled:text-gray-2`,
  default: tw`${isHollowStyle} text-brand-color-1-1 disabled:text-brand-color-1-disabled`,
}

const isOutlineStyle = 'bg-transparent border-2'
const outlineVariants = {
  primary: tw`${isOutlineStyle} border-brand-color-1-1 text-brand-color-1-1 hover:border-brand-color-1-1 hover:text-brand-color-1-1 active:bg-brand-color-1-1 active:text-white disabled:text-brand-color-1-disabled`,
  gray: tw`${isOutlineStyle} border-brand-color-3-1 text-brand-color-3-1 hover:border-brand-color-3-1 hover:text-brand-color-3-1 active:bg-brand-color-3-1 active:text-white disabled:text-brand-color-3-disabled`,
  black: tw`${isOutlineStyle} border-black text-black hover:border-gray-5 hover:text-gray-5 active:bg-black active:text-white disabled:text-gray-2`,
  white: tw`${isOutlineStyle} border-white text-white hover:border-gray-2 hover:text-gray-2 disabled:text-gray-2`,
  default: tw`${isOutlineStyle} border-brand-color-1-1 text-brand-color-1-1 hover:border-brand-color-1-1 hover:text-brand-color-1-1 active:bg-brand-color-1-1 active:text-white disabled:text-brand-color-1-disabled`,
}

export const getButtonStyle = (buttonStyle: ButtonStyle, variant: ButtonVariant) => {
  switch (buttonStyle) {
    case 'hollow':
      return hollowVariants[variant]
    case 'outline':
      return outlineVariants[variant]
    default:
      return variants[variant]
  }
}

export const buttonSpacing = tw`
  flex
  min-h-13
  min-w-13
  lg:min-h-17
  lg:min-w-17
  lg:px-10
  items-center
  justify-center
  text-2xl
`

export const buttonStyle = tw`
  text-2xl
  rounded-full
  font-sans
  text-white
  justify-center
  transition
  duration-300
  ease-in-out
  disabled:pointer-events-none
  focus:outline-none
  focus:shadow-none
  focus:ring-0
  border-transparent border-2
`

export const baseStyle = css`
  ${buttonSpacing}
  ${buttonStyle}
  svg {
    ${tw`pointer-events-none`}
  }
`
