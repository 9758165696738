import React from 'react'
import tw from 'twin.macro'
import Icon from '../Icon'
import type { TTextIconPatternProps } from './interface'

const TextIconPattern = (props: TTextIconPatternProps) => {
  const { iconPosition, icon, children } = props
  return (
    <span tw="flex items-center focus:!outline-none focus:!shadow-none focus:!ring-0">
      {iconPosition === 'right' && children}
      {icon &&
        (children ? <Icon icon={icon} css={iconPosition === 'right' ? tw`ml-6` : tw`mr-6`} /> : <Icon icon={icon} />)}
      {iconPosition === 'left' && children}
    </span>
  )
}

export default TextIconPattern
