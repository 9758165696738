import type { TProcessingInstruction } from '@okam/html2react'
import { parseHtmlStyle } from '@okam/html2react'
import { Button } from '@okam/shared-ui'
import { nanoid } from 'nanoid'
import React from 'react'

const replaceButton: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return node.type != null && node.type === 'tag' && node.name === 'button'
  },

  processNode(node, children) {
    const { attribs } = node
    const id = nanoid()
    return (
      // eslint-disable-next-line prettier/prettier
      <Button
        buttonStyle={attribs.buttonStyle}
        key={id}
        css={parseHtmlStyle(attribs.style)}
        href={attribs.href}
        as={node.name}
        {...attribs}
      >
        {children}
      </Button>
    )
  },
}

export default replaceButton
