import type { TTypographyProps } from '@okam/shared-ui'
import { useTheme } from '@okam/shared-ui'
import BrandTypography from '../BrandTypography'

const Paragraph = (props: TTypographyProps) => {
  const { children, as, variant } = props
  const { brandTheme } = useTheme()

  return (
    <BrandTypography as={as} {...props} css={brandTheme.typography({ variant })}>
      {children}
    </BrandTypography>
  )
}

export default Paragraph
