import { Global } from '@emotion/react'
import React from 'react'
import tw, { css, GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css({
  body: {
    ...tw`antialiased relative`,
  },
  hr: {
    ...tw`my-5 h-0.5 last:mb-0`,
  },
  'body main::after': {
    content: "''",
    ...tw`absolute z-30 top-0 bottom-0 right-0 bg-secondary-1 w-[10px] lg:w-7 2xl:w-[34px] h-full`,
  },
  a: {
    ...tw`font-body`,
  },
})

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
)

export default GlobalStyles
