import { cache } from '@emotion/css'
import { CacheProvider } from '@emotion/react'
import { BrandThemeProvider } from '@okam/brand-ui'
import { Html2ReactProvider } from '@okam/html2react'
import { IsClientContextProvider } from '@okam/shared-ui'
import { SSRProvider } from '@react-aria/ssr'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { I18nProvider } from 'react-aria'
import { initialInstructions } from '@/lib/html2react'
import GlobalStyles from 'styles/GlobalStyles'

export const AppProviders = ({ children, locale }: { children: React.ReactNode; locale?: string }) => {
  const [queryClient] = React.useState(() => new QueryClient())
  return (
    <>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <SSRProvider>
          <IsClientContextProvider>
            <I18nProvider locale={locale}>
              <CacheProvider value={cache}>
                <Html2ReactProvider initialInstructions={initialInstructions}>
                  <BrandThemeProvider>{children}</BrandThemeProvider>
                </Html2ReactProvider>
              </CacheProvider>
            </I18nProvider>
          </IsClientContextProvider>
        </SSRProvider>
        <ReactQueryDevtools position="bottom-left" />
      </QueryClientProvider>
    </>
  )
}
