import { Heading } from '@okam/brand-ui'
import type { TProcessingInstruction } from '@okam/html2react'
import { parseHtmlStyle } from '@okam/html2react'
import type { TypographyVariant } from '@okam/shared-ui'
import { nanoid } from 'nanoid'
import React from 'react'

function stringToTypoVariant(str: string): TypographyVariant | undefined {
  const variants: TypographyVariant[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'leading', 'p', 'footnote']
  const variant = variants.find((x) => x === str)
  return variant ?? undefined
}

const replaceHeader: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return (
      node.type != null && node.type === 'tag' && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(node.name?.toString())
    )
  },

  processNode(node, children) {
    const { attribs } = node
    const id = nanoid()
    return (
      <Heading
        key={id}
        css={parseHtmlStyle(attribs.style)}
        variant={stringToTypoVariant(node.name?.toString())}
        as={node.name}
      >
        {children}
      </Heading>
    )
  },
}

export default replaceHeader
