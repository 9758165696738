/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import type { RefObject } from 'react'
import React, { useRef } from 'react'
import { useButton, useLink } from 'react-aria'
import tw, { styled } from 'twin.macro'
import TextIconPattern from '../TextIconPattern'
import type { TButtonProps } from './interface'
import { getButtonStyle, baseStyle } from './styles'

const StyledButton = styled.button(({ variant = 'primary', buttonStyle = 'default' }: TButtonProps) => [
  baseStyle,
  getButtonStyle(buttonStyle, variant),
])

const Anchor = React.forwardRef((props: TButtonProps, forwardRef: React.Ref<HTMLButtonElement & HTMLAnchorElement>) => {
  const { icon, handlePress, children, as, iconPosition = 'right', dataGtm } = props
  const ref = forwardRef
  const { linkProps } = useLink(
    { ...props, elementType: as?.toString(), onPress: handlePress },
    (forwardRef as RefObject<HTMLElement>) ?? ref,
  )

  return (
    <StyledButton
      data-gtm={dataGtm ?? 'button'}
      css={icon && !children && tw`!p-0`}
      ref={ref}
      {...linkProps}
      {...props}
    >
      <TextIconPattern icon={icon} iconPosition={iconPosition}>
        {children}
      </TextIconPattern>
    </StyledButton>
  )
})

const Button = React.forwardRef((props: TButtonProps, forwardRef: React.Ref<HTMLButtonElement & HTMLAnchorElement>) => {
  const { icon, handlePress, children, as, iconPosition = 'right' } = props
  const ref = forwardRef
  const { buttonProps } = useButton(
    { ...props, elementType: as, onPress: handlePress },
    (forwardRef as RefObject<HTMLElement>) ?? ref,
  )

  return (
    <StyledButton data-gtm="button" css={icon && !children && tw`!p-0`} ref={ref} {...buttonProps} {...props}>
      <TextIconPattern icon={icon} iconPosition={iconPosition}>
        {children}
      </TextIconPattern>
    </StyledButton>
  )
})

export const ButtonWithForwardRef = React.forwardRef(
  (props: TButtonProps, ref: React.Ref<HTMLButtonElement & HTMLAnchorElement>) => {
    const { as } = props
    if (as === 'a') return <Anchor ref={ref} {...props} />
    return <Button ref={ref} {...props} />
  },
)

const ButtonComponent = (props: TButtonProps) => {
  const { as } = props
  const ref = useRef(null)
  if (as === 'a') return <Anchor ref={ref} {...props} />
  return <Button ref={ref} {...props} />
}

export default React.memo(ButtonComponent)
