import type { TProcessingInstruction } from '@okam/html2react'
import replaceButton from './ReplaceButton'
import replaceHeader from './ReplaceHeader'
import replaceHrefLink from './ReplaceHrefLink'
import replaceImage from './ReplaceImage'
import replaceParagraph from './ReplaceParagraph'
import replaceSelect from './ReplaceSelect'

const removeEmptyParagraph: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return (
      node.type != null &&
      node.type === 'tag' &&
      node.name === 'p' &&
      (Array.isArray(node.children) && node.children.length) === 0
    )
  },
  processNode() {
    return ''
  },
}

export const initialInstructions = [
  removeEmptyParagraph,
  replaceButton,
  replaceHeader,
  replaceHrefLink,
  replaceImage,
  replaceParagraph,
  replaceSelect,
]
