import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion'
import React from 'react'

const revealAnim = {
  name: 'Reveal',
  variants: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  transition: {
    duration: 0.7,
  },
}

export const RevealAnim = ({ children, route }: { children: React.ReactNode; route: string }) => (
  <LazyMotion features={domAnimation}>
    <AnimatePresence onExitComplete={() => window.scrollTo(0, 0)} mode="wait">
      <m.div
        key={route.concat(revealAnim.name)}
        className="page-wrap"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={revealAnim.variants}
        transition={revealAnim.transition}
      >
        {children}
      </m.div>
    </AnimatePresence>
  </LazyMotion>
)

export default RevealAnim
