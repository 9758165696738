import type { LegacyRef } from 'react'
import React from 'react'
import { DismissButton, Overlay, usePopover } from 'react-aria'
import type { TPopoverProps } from './Popover.interface'

const Popover = (props: TPopoverProps) => {
  const ref = React.useRef(null)
  const { popoverRef = ref, state, children, popoverStyles } = props
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  )

  return (
    <Overlay portalContainer={document.body}>
      <div {...underlayProps} style={{ position: 'fixed', inset: 0 }} />
      <div {...popoverProps} css={[popoverStyles]} ref={popoverRef as LegacyRef<HTMLDivElement>}>
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  )
}

export default Popover
