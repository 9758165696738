/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from 'twin.macro'
import type { TypographyVariant, TTypographyProps, TTypographyToken } from './interface'
import { baseToken } from './tokens'

const mergeToken = (tokens: TTypographyToken) => ({ ...baseToken, ...tokens })

const typographyStyle = ({ tokens = {} }: TTypographyProps) => {
  return [mergeToken(tokens)]
}

export default typographyStyle
