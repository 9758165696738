const parseHtmlStyle = (str: string) =>
  typeof str === 'string'
    ? str.split(';').reduce((acc, style) => {
        const colonPosition: number = style.indexOf(':')

        if (colonPosition === -1) {
          return acc
        }

        const camelCaseProperty = style
          .substring(0, colonPosition)
          .trim()
          .replace(/^-ms-/, 'ms-')
          .replace(/-./g, (c) => c.substring(1).toUpperCase())
        const value = style.substring(colonPosition + 1).trim()

        return value ? { ...acc, [camelCaseProperty]: value } : acc
      }, {})
    : {}

export default parseHtmlStyle
