import type { TProcessingInstruction } from '@okam/html2react'
import { parseHtmlStyle } from '@okam/html2react'
import { Select } from '@okam/shared-ui'
import { nanoid } from 'nanoid'
import React from 'react'

const replaceSelect: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return node.type !== null && node.type === 'tag' && node.name === 'select'
  },

  processNode(node, children) {
    const { attribs } = node
    const id = nanoid()
    return (
      <Select key={id} css={parseHtmlStyle(attribs.style)} id={id} name={attribs.name} placeholderLabel="Select">
        {children}
      </Select>
    )
  },
}

export default replaceSelect
