import { isEmpty } from 'lodash'
import React, { useRef } from 'react'
import { HiddenSelect, useSelect } from 'react-aria'
import { get, useFormContext } from 'react-hook-form'
import { useSelectState } from 'react-stately'
import { ButtonWithForwardRef } from '../../Button'
import { ListBox } from './components/Listbox'
import Popover from './components/Popover'
import type { TSelectProps } from './Select.interface'

const Select = (props: TSelectProps) => {
  const {
    name,
    placeholderLabel,
    disabled,
    hookFormRef,
    isError,
    errorMessage,
    icon,
    popoverStyles,
    selection = 'single',
    ariaLabel,
  } = props
  const fieldRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null)
  const state = useSelectState(props)
  const { triggerProps, menuProps } = useSelect({ 'aria-label': ariaLabel, ...props }, state, fieldRef)

  return (
    <div tw="inline-block relative w-full">
      <HiddenSelect {...hookFormRef} state={state} label={ariaLabel} triggerRef={fieldRef} name={name} isDisabled />
      <ButtonWithForwardRef
        {...triggerProps}
        ref={fieldRef}
        buttonStyle="default"
        variant="primary"
        icon={icon ?? 'ChevronDown'}
        disabled={disabled}
      >
        <div>{state.selectedItem ? state.selectedItem.rendered : placeholderLabel}</div>
      </ButtonWithForwardRef>
      {state.isOpen && (
        <Popover state={state} triggerRef={fieldRef} placement="bottom start" popoverStyles={popoverStyles ?? false}>
          <ListBox {...menuProps} selectionMode={selection} state={state} />
        </Popover>
      )}
      {isError && <span tw="text-sm text-red-600 px-6">{errorMessage}</span>}
    </div>
  )
}

export const ReactHookFormSelect = (props: TSelectProps) => {
  const { name, required, children } = props
  const { register, formState } = useFormContext()
  const errors = get(formState.errors, name)
  const errMsg = errors?.message ?? undefined
  const { ref } = register(name, {
    required: required ? 'This is an error message' : false,
  })

  return (
    <Select {...props} isError={!isEmpty(errMsg)} errorMessage={errMsg} hookFormRef={ref}>
      {children}
    </Select>
  )
}

export default Select
