import type { AppProps as NextAppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import NextNProgress from 'nextjs-progressbar'
import { QueryClient, QueryClientProvider } from 'react-query'
import { theme } from 'twin.macro'
import { RevealAnim } from '@/components/animations/RevealAnim'
import Gtm from '@/lib/gtm/Gtm'
import nextI18nextConfig from '../../next-i18next.config'
import { AppProviders } from '../AppProviders'
import '@bedrock-layout/css'
import MarkerIo from '../MarkerIo'

/**
 * Local fonts
 * @link https://fontsource.org/docs/guides/nextjs
 */

// Workaround for https://github.com/zeit/next.js/issues/8592
export type AppProps = NextAppProps & {
  /** Will be defined only is there was an error */
  err?: Error
}

const queryClient = new QueryClient()

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = (appProps: AppProps) => {
  const { Component, pageProps, err, router } = appProps
  return (
    <>
      <NextNProgress color={theme('colors.secondary.1')} height={5} tw="z-50" />
      <AppProviders locale={router.locale}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        {process.env.NEXT_PUBLIC_GTM_ID && <Gtm gtmId={process.env.NEXT_PUBLIC_GTM_ID} />}
        {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
        <QueryClientProvider client={queryClient}>
          <RevealAnim route={router.route}>
            <Component {...pageProps} err={err} />
          </RevealAnim>
        </QueryClientProvider>
        <MarkerIo id={process.env.NEXT_PUBLIC_MARKER_IO_ID} />
      </AppProviders>
    </>
  )
}

/**
 * Generally don't enable getInitialProp if you don't need to,
 * all your pages will be served server-side (no static optimizations).
 */
/*
MyApp.getInitialProps = async appContext => {
   // calls page's `getInitialProps` and fills `appProps.pageProps`
   const appProps = await App.getInitialProps(appContext)
   return { ...appProps }
}
*/

export default appWithTranslation(MyApp, {
  ...nextI18nextConfig,
})
