import React, { useRef, useMemo, useCallback } from 'react'
import type { TProcessingInstruction } from 'html-to-react'
import getProcessingInstructions from '../helpers/parseHTMLToReact'
import { Parser, isValidNode } from '../parser'
import type { THtml2ReactContext, THtml2ReactProps } from './provider.interface'

const Html2ReactContext = React.createContext<THtml2ReactContext | undefined>(undefined)

function Html2ReactProvider({ children, initialInstructions = null }: THtml2ReactProps) {
  const replaceInstruction = useRef<TProcessingInstruction[] | null>(initialInstructions)

  const setReplaceInstruction = useCallback((instructions: TProcessingInstruction[]) => {
    const processingInstructions = getProcessingInstructions(instructions)
    replaceInstruction.current = processingInstructions
  }, [])

  const parseHTMLToReact = useCallback((postContent: string) => {
    const content = postContent?.replace(/\r?\n|\r/g, '')
    const processingInstructions = getProcessingInstructions(replaceInstruction.current ?? [])
    return Parser.parseWithInstructions(content, isValidNode, processingInstructions)
  }, [])

  const memoizedValue = useMemo(
    () => ({
      setReplaceInstruction,
      parseHTMLToReact,
    }),
    [setReplaceInstruction, parseHTMLToReact],
  )

  return <Html2ReactContext.Provider value={memoizedValue}>{children}</Html2ReactContext.Provider>
}

function useHtml2React() {
  const context = React.useContext(Html2ReactContext)
  if (context === undefined) {
    throw new Error('useHtml2React must be used within a Html2ReactProvider')
  }
  return context
}

export { Html2ReactProvider, useHtml2React }
