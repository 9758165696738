import type { TTypographyProps } from '@okam/shared-ui'
import { useTheme } from '@okam/shared-ui'
import Balancer from 'react-wrap-balancer'
import BrandTypography from '../BrandTypography'

const Heading = (props: TTypographyProps) => {
  const { children, as, variant } = props
  const { brandTheme } = useTheme()

  return (
    <BrandTypography as={as} {...props} css={brandTheme.typography({ variant })}>
      <Balancer>{children}</Balancer>
    </BrandTypography>
  )
}

export default Heading
