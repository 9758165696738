import { processNodeDefinitions } from '../parser'
import type { TProcessingInstruction } from '../types'

const processingInstructions: TProcessingInstruction[] = [
  {
    shouldProcessNode() {
      return true
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

const getProcessingInstructions = (nodesToReplaces: TProcessingInstruction[]) => [
  ...nodesToReplaces,
  ...processingInstructions,
]

export default getProcessingInstructions
