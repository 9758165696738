import type { TTheme } from '@okam/shared-ui'
import { ThemeContextProvider } from '@okam/shared-ui'
import React from 'react'
import tw, { css } from 'twin.macro'

const brandTheme: TTheme = {
  typography: ({ variant }) => [
    tw`!font-body`,
    variant === 'h1' && tw`text-5xl font-semibold mb-2 xl:mb-4`,
    variant === 'h2' && tw`text-4xl font-semibold`,
    variant === 'h3' && tw`text-3xl font-semibold`,
    variant === 'h4' && tw`text-2xl font-semibold`,
    variant === 'h5' && tw`text-xl`,
    variant === 'h6' && tw`text-lg`,
    variant === 'leading' && tw`text-leading`,
    variant === 'p' && tw`text-paragraph`,
    variant === 'footnote' && tw`text-footnote`,
  ],
  button: ({ variant, buttonStyle, disabled, iconPosition }) => [
    css`
      ${tw`!font-body !text-button uppercase !min-h-12 !min-w-12 !px-8 focus-visible:!shadow-focus`}
    `,
    disabled === true && tw`opacity-50`,
    iconPosition === 'right' &&
      css`
        svg {
          ${tw`!ml-3`}
        }
      `,
    iconPosition === 'left' &&
      css`
        svg {
          ${tw`!mr-3`}
        }
      `,
    variant === 'primary' &&
      buttonStyle === 'default' &&
      tw`!bg-tertiary-2 !text-black hover:!bg-tertiary-5 hover:!text-white active:!bg-tertiary-4 active:!text-white`,
    variant === 'white' &&
      buttonStyle === 'default' &&
      tw`!bg-white !text-black hover:!bg-tertiary-5 hover:!text-white active:!bg-tertiary-4 active:!text-white`,
    variant === 'black' &&
      buttonStyle === 'outline' &&
      css`
        ${tw`!bg-transparent text-black !border-0 !px-0 !min-w-fit `}
        span {
          ${tw`border-b border-black`}
          svg {
            ${tw`-mt-1`}
          }
        }
        &:hover,
        &:active {
          span {
            ${tw`text-primary-1 border-primary-1`}
          }
        }
      `,
    variant === 'white' &&
      buttonStyle === 'outline' &&
      css`
        ${tw`!bg-transparent text-white !border-0 !px-0 !min-w-fit `}
        span {
          ${tw`border-b border-white`}
        }
        &:hover,
        &:active {
          ${tw`text-gray-400`}
          span {
            ${tw`border-gray-400`}
          }
        }
      `,
    variant === 'black' && buttonStyle === 'hollow' && tw`bg-transparent text-black !border-0 hover:text-primary-1`,
    variant === 'white' && buttonStyle === 'hollow' && tw`bg-transparent text-white !border-0 hover:text-gray-400`,
  ],
  textInput: ({ isError, disabled }) => [
    css`
      ${tw`border rounded-none border-tertiary-3 px-5 py-4 h-auto transition text-black placeholder:text-tertiary-4 hover:border-tertiary-5 active:border-tertiary-5 focus:!outline-none focus-visible:outline-offset-0 focus-visible:!outline-primary-2`}
    `,
    disabled === true && tw`opacity-30 bg-transparent`,
    isError === true &&
      css`
        ${tw`[border-color:#CE3F36] [color:#CE3F36] placeholder:[color:#CE3F36]`}
      `,
  ],
  searchInput: ({ isDisabled }) => [
    css`
      ${tw`!font-body !text-paragraph !rounded-none !border-t-0 !border-x-0 !px-0 !pt-0 !pb-1 h-auto w-full transition !border-b-tertiary-3 !text-tertiary-6 placeholder:!text-tertiary-3 active:!border-tertiary-6 active:placeholder:!text-tertiary-4 focus:ring-0 focus:!border-tertiary-3 focus-visible:outline-2 focus-visible:outline-primary-2 focus-visible:outline-offset-2 `}
    `,
    isDisabled === true &&
      css`
        ${tw`opacity-30 bg-transparent`}
      `,
  ],
  errorMessage: ({ isError }) => [isError === true && tw`[color:#CE3F36] px-5 [font-size: .75rem]`],
}

export const BrandThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <ThemeContextProvider brandTheme={brandTheme}>{children}</ThemeContextProvider>
)

export default brandTheme
