import Script from 'next/script'

interface MarkerProps {
  id: string | undefined
}

const MarkerIo = ({ id }: MarkerProps) => {
  if (!id) return null
  return (
    <>
      <Script id="marker-io-id" key="marker-io-id">
        {`
          window.markerConfig = {
            destination: '${id}',
            useNativeScreenshot: true,
          };
        `}
      </Script>
      <Script id="marker-io-script" key="marker-io-script">
        {`
          !function(e,r,t){if(e.__Marker)return;e.__Marker={};var n=r.createElement("script");n.async=1,n.src="https://edge.marker.io/latest/shim.js";var s=r.getElementsByTagName("script")[0];s.parentNode.insertBefore(n,s)}(window,document);
        `}
      </Script>
    </>
  )
}

export default MarkerIo
