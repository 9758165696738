import { Typography, useTheme } from '@okam/shared-ui'
import type { TTypographyProps } from '@okam/shared-ui'

const BrandTypography = (props: TTypographyProps) => {
  const { variant, children } = props
  const { brandTheme } = useTheme()
  return (
    <Typography {...props} css={brandTheme.typography({ variant })}>
      {children}
    </Typography>
  )
}

export default BrandTypography
