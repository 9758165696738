import { Paragraph } from '@okam/brand-ui'
import type { TProcessingInstruction } from '@okam/html2react'
import { parseHtmlStyle } from '@okam/html2react'
import { nanoid } from 'nanoid'
import React from 'react'

const replaceParagraph: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return node.type !== null && node.type === 'tag' && node.name === 'p'
  },

  processNode(node, children) {
    const { attribs } = node
    const id = nanoid()
    return (
      <Paragraph key={id} css={parseHtmlStyle(attribs.style)} as={node.name}>
        {children}
      </Paragraph>
    )
  },
}

export default replaceParagraph
